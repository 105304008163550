/**
 * Const with params element
 * Card hero post component
 * Display a card of summary last Post in hero
 */
/* Import section */
import { GatsbyImage } from "gatsby-plugin-image"
import React, { Component } from "react"
import Cardpost from "../components/cardpost"
import Ctabuttonwitharrow from "../components/ctabuttonwitharrow"
import TruncateText from "../components/truncatetext"
import "../styles/components/cardheropost.scss"

/* Declaration class */
class Cardheropost extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0,
    }
  }

  componentDidMount() {
    this.handleWindowSizeChange()
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    if(window.innerWidth !== this.state.width) {
      this.setState({
        width: window.innerWidth
      })
    }
  }

  render () {
    const {
      titlePost,
      leadPost,
      slugPost,
      srcImgPost,
      datePost,
      typeSlugPost,
    } = this.props;

    const isMobile = this.state.width <= 767

    if(!isMobile){
      return (
        <div className="card-hero-post card-clickable">
          <GatsbyImage className="background-hero-post" image={srcImgPost} alt={`image ${titlePost}`} />
          <div className="box-hero-post">
            <p className="type-date"><span>{datePost}</span></p>
            <h2><TruncateText str={titlePost} length="60" ending="..." /></h2>
            <div className="box-lead">
              <p><TruncateText str={leadPost} length="200" ending="..." /></p>
            </div>
            <div className="box-ctatext-card">
              <Ctabuttonwitharrow ctaLabel="Read More" ctaLink={`/${typeSlugPost}/${slugPost}`} specificStyle="text-arrow" colorStyle="white-format" />
            </div>
          </div>
          <a href={`/${typeSlugPost}/${slugPost}/`} aria-label="Read More"><span className="span-cta-card" >Read More</span></a>
        </div>
        )
    }else {
      return (
        <Cardpost
          titlePost={titlePost}
          leadPost= {leadPost}
          srcImgPost={srcImgPost}
          slugPost={slugPost}
          typeSlugPost={typeSlugPost}
          datePost={datePost}
        />
        )
    }
  }
}

/* Export function */
export default Cardheropost
