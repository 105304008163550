/**
 * Post list Template (blog or news)
 */
/* Import section */
import { graphql } from "gatsby"
import React, { useState, useEffect, useRef } from "react"
import Bannerwithcta from "../components/bannerwithcta"
import Cardheropost from "../components/cardheropost"
import Herotype2 from "../components/herotype2"
import Layout from "../components/layout"
import Renderpost from "../components/renderpost"
import Seo from "../components/seo"
import "../styles/templates/postlist.scss"

const postsPerPage = 6
let arrayForHoldingPosts = []


/* Declaration class */
const PostListTemplate = ({ data }) => {

  //Const data page
  const dataHeroPost = data.lastPost.edges[0].node
  const dataPosts = data.allWpPost
  const h1Post = dataHeroPost.categories.nodes[0].acfCatPosts.titleHeroPosts
  const catDesc = dataHeroPost.categories.nodes[0].acfCatPosts.textHeroPosts

  //Load More functionnality
  const [postsToShow, setPostsToShow] = useState([])
  const [next, setNext] = useState(6)
  const [slugPost, setSlugPost] = useState(0)
  const prevSlugPostRef = useRef()
  const prevSlugPost = prevSlugPostRef.current
  const lengthPostsToShow = postsToShow.length
  const postsPosts = dataPosts.edges
  const lenghtPostsPosts = postsPosts.length
  var showLoadMore = (lenghtPostsPosts > postsPerPage && lenghtPostsPosts !== lengthPostsToShow) ? true : false

  if(!showLoadMore || prevSlugPost === undefined) {
    arrayForHoldingPosts = []
  }

  const loopWithSlice = (start, end) => {
    const slicedPosts = postsPosts.slice(start, end)
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts]
    setPostsToShow(arrayForHoldingPosts)
  }

  useEffect(() => {
    loopWithSlice(0, postsPerPage)
    prevSlugPostRef.current = slugPost
  }, [])

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage)
    setNext(next + postsPerPage)
    setSlugPost(dataHeroPost.categories.nodes[0].slug)
  }

  //Const data Options
  const dataOptionsbanner = data.options.acfPageOptions
  var arrOptionsbanner = []
  if(dataHeroPost.categories.nodes[0].slug === "blog") {
    arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
      return element.topicBannerCta === "news"
    })
  }else if(dataHeroPost.categories.nodes[0].slug === "news") {
    arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
      return element.topicBannerCta === "blog"
    })
  }

  return (
    <Layout>
      <Seo
        title={dataHeroPost.categories.nodes[0].seo.title}
        description={dataHeroPost.categories.nodes[0].seo.metaDesc}
      />

      <section className="section-hero-post-list top-section">
        <Herotype2 h1title={h1Post} textIntro={catDesc} colorBar="white" />
      </section>

      {/* Section with loop on all Posts */}
      <section className="section-loop-posts">
        <div className="hero-loop-posts wrap-maincontent">
          <div className="card-hero-subtext">
            <div className="hero-body" dangerouslySetInnerHTML={{__html:dataHeroPost.categories.nodes[0].acfCatPosts.subtextPosts}} />
          </div>
          <Cardheropost
            titlePost= {dataHeroPost.title}
            leadPost= {dataHeroPost.acfPostBlogNews.leadArticle}
            srcImgPost= {dataHeroPost.acfPostBlogNews.imageArticle.localFile.childImageSharp.gatsbyImageData}
            slugPost= {dataHeroPost.slug}
            datePost= {dataHeroPost.date}
            typeSlugPost= {dataHeroPost.categories.nodes[0].slug}
          />
        </div>
        <Renderpost postsToRender={postsToShow} />
        {showLoadMore &&
        <div className="box-loadmore">
          <button onClick={handleShowMorePosts}>Load More Articles</button>
        </div>
        }
      </section>

      {/* Banner CTA */}
      <Bannerwithcta
        classBanner="section-banner-posts"
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}

/* Export template informations */
export default PostListTemplate

//QUERY GRAPHSQL ON POST
export const query = graphql`
  query($slug: String!) {
    allWpPost(filter: {categories: {nodes: {elemMatch: {slug: {eq: $slug}}}}}, sort: {fields: [date], order:DESC}, skip: 1) {
      edges {
        node {
          databaseId
          title
          slug
          date (
            formatString: "DD MMMM YYYY"
          )
          categories {
            nodes {
              name
              slug
            }
          }
          acfPostBlogNews {
            leadArticle
            imageArticle {
              localFile {
                childImageSharp {
                  gatsbyImageData (layout: CONSTRAINED, width: 800, aspectRatio: 1.5)
                }
              }
            }
          }
        }
      }
    }
    lastPost: allWpPost(limit: 1, filter: {categories: {nodes: {elemMatch: {slug: {eq: $slug}}}}}, sort: {fields: [date], order:DESC}) {
      edges {
        node {
          title
          slug
          date (
            formatString: "DD MMMM YYYY"
          )
          categories {
            nodes {
              name
              slug
              acfCatPosts {
                titleHeroPosts
                textHeroPosts
                subtextPosts
              }
              seo {
                title
                metaDesc
              }
            }
          }
          acfPostBlogNews {
            leadArticle
            imageArticle {
              localFile {
                childImageSharp {
                  gatsbyImageData (layout: CONSTRAINED, width: 1000, aspectRatio: 1.5)
                }
              }
            }
          }
        }
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterBannerCta {
          ctaLabelBanner
          ctaLinkBanner
          textBannerCta
          titleBannerCta
          topicBannerCta
        }
      }
    }
  }
`
